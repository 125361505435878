import * as _ from "./libs/underscore-min"

function focusNextOf(currentElem){
  var inputs = $("[tabindex]");
  inputs = _.filter(inputs, function(elem){
    return $(elem).is(':visible') && $(elem).attr('type') !== 'hidden'
  });
  inputs = _.sortBy(inputs, function(elem){
    return parseInt($(elem).attr("tabindex"));
  });
  var currentIndex = _.indexOf(inputs, currentElem);
  var nextIndex = (currentIndex + 1) >= inputs.length ? 0 : (currentIndex + 1);
  setTimeout(function(){
    $(inputs[nextIndex]).focus().select();
  }, 50);
}

function setupFocusNext(elem){
  elem.keypress(function(e) {
    if ((e.which ? e.which : e.keyCode) === 13) {
      focusNextOf(this);
      if($(this).attr('type') !== 'submit' && $(this).prop("tagName") !== 'BUTTON' && !$(this).hasClass('btn')){
        e.preventDefault();
      }
    }
  });
}

$(function() {
  setupFocusNext($("[tabindex]"));
});

$(function(){

  // 強制的に Enter が押されたことにする
  var forceEnter = function(elem){
    var evt = $.Event('keypress');
    evt.keyCode = 13;
    elem.trigger(evt);
  };

  $('.datepicker').focusout(function(){
    forceEnter($(this));
  });

});

export { focusNextOf, setupFocusNext };
