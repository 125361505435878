// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//= require jquery3
//= require jquery_ujs
//= require jquery-ui
//= require jquery-ui-bundle
//= require rails-ujs
//= require activestorage
//= require cocoon
//= require nested_form_fields
//= require turbolinks
//= require bootstrap
//= require select2
//= require select2-bootstrap-theme
//= require_tree .

import 'jquery-ui-bundle';

import "bootstrap"
import "../stylesheets/application"

import "cocoon"

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

import "./libs/underscore-min"

//$.fn.select2.defaults.set("width", "100%");       // tdタグの中に入れたときにだんだん大きくなっていくのを防ぐ
//$.fn.select2.defaults.set("theme", "bootstrap");  // テーマの指定

import "./reservation-calendar"
import "./calendar-input"
import "./combobox"
import "./datetimepicker"
import "./sales-recording-calculator"
import "./toggle-past-day"
import "./toggle-abnormal-day"
import "./market-price-calculate-ave"
import "./sortable-buyer-products"
import "./reservation-add-product-button"
import "./pages/admins_sales_aggregates"
import "./pages/admins_buyer_products"

import "dropzone"
import { confirm } from "dropzone";

import "./upload-file"

import "./focus-next-with-enter-key"
import "./reservations-focus-first-quantity"

// ロード中の body を見せないようにする
$(function(){
    $('body').css('visibility', 'visible');
});