$(function(){
    var toggleButton = $(".toggle-past-day");
    toggleButton.click(function(){
       if($(this).attr("opened") === "false"){
           $('.past-input').removeAttr('readonly');
           $('.past-commit').removeAttr('disabled');

           $(this).text($(this).attr("text-close"));
           $(this).attr("opened", "true");
           $('input#past_day_editing').val(true)
       }else{
           $('.past-input').attr('readonly', 'readonly');
           $('.past-commit').attr('disabled', 'disabled');

           $(this).text($(this).attr("text-open"));
           $(this).attr("opened", "false");
           $('input#past_day_editing').val(false)
       }
    });
});