$(function(){
    var inputs = $('.calendar-input-table input.quantity');
    inputs.on('keyup change', function(){
        var td = $(this).closest('td');
        if(isNaN(parseInt($(this).val()))){
            td.removeClass("filled");
        }else{
            td.addClass("filled");
        }
    });
    inputs.trigger('change');
});
