function getRounding(){
    var make = function(roundingFunc){
        return function(v) {
            return roundingFunc(Math.round(v * 1000) / 1000)
        };
    };
    if($('#sales_record_rounding_type_code_floor').is(':checked')){
        return make(Math.floor);
    }else if($('#sales_record_rounding_type_code_ceil').is(':checked')){
        return make(Math.ceil);
    }else{
        return make(Math.round);
    }
}

function getUnitRounding(){
    var make = function(roundingFunc){
        return function(v) {
            return roundingFunc(Math.round(v * 1000) / 1000)
        };
    };
    if($('#sales_record_unit_rounding_type_code_floor').is(':checked')){
        return make(Math.floor);
    }else if($('#sales_record_unit_rounding_type_code_ceil').is(':checked')){
        return make(Math.ceil);
    }else{
        return make(Math.round);
    }
}

function setSaleUnitPrice(priceTag, rawPrice){
    priceTag.attr('raw_price', rawPrice);
    updateSaleUnitPrice(priceTag);
}

function updateSaleUnitPrice(priceTag){
    var rawPrice = parseFloat(priceTag.attr('raw_price'));
    if(!isNaN(rawPrice)){
        var price = getUnitRounding()(rawPrice);
        price = parseInt(price);
        priceTag.val(price).change();
    }
}

$(function() {
    $('.section.sales_record .sales_record_item').find([
        '.bid_price',
        '.additional_price_before',
        '.price_multipliers',
        '.additional_price_after'
    ].join(', ')).change(function(){
        var itemTag = $(this).closest('.sales_record_item');
        var bidPrice = parseInt(itemTag.find('.bid_price').val());
        var additionalPriceBefore = parseInt(itemTag.find('.additional_price_before').val());
        var priceMultipliers = parseFloat(itemTag.find('.price_multipliers').val());
        var additionalPriceAfter = parseInt(itemTag.find('.additional_price_after').val());

        if(isNaN(bidPrice)){
            return;
        }
        additionalPriceBefore = isNaN(additionalPriceBefore) ? 0 : additionalPriceBefore;
        priceMultipliers = isNaN(priceMultipliers) ? 1 : priceMultipliers;
        additionalPriceAfter = isNaN(additionalPriceAfter) ? 0 : additionalPriceAfter;

        var priceTag = itemTag.find('.sale_price');
        var salePrice = ((bidPrice + additionalPriceBefore) * priceMultipliers) + additionalPriceAfter;
        setSaleUnitPrice(priceTag, salePrice);
        priceTag.change();
    });
});

$(function() {
    $('.section.sales_record .sales_record_item .btn-success').click(function () {
        var itemTag = $(this).closest('.sales_record_item');
        itemTag.find('.additional_price_before').val(itemTag.find('.tmp_additional_price_before').val());
        itemTag.find('.price_multipliers').val(itemTag.find('.tmp_price_multipliers').val());
        itemTag.find('.additional_price_after').val(itemTag.find('.tmp_additional_price_after').val());
        itemTag.find('.bid_price').change();
    });

    $('.section.sales_record .sales_record_item .btn-secondary').click(function () {
        var itemTag = $(this).closest('.sales_record_item');
        itemTag.find('.tmp_additional_price_before').val(itemTag.find('.additional_price_before').val());
        itemTag.find('.tmp_price_multipliers').val(itemTag.find('.price_multipliers').val());
        itemTag.find('.tmp_additional_price_after').val(itemTag.find('.additional_price_after').val());
    });
});

function setRecordingPrice(recordingPriceTag, rawPrice){
    recordingPriceTag.attr('raw_price', rawPrice);
    updateRecordingPrice(recordingPriceTag);
}

function updateRecordingPrice(recordingPriceTag){
    var rawPrice = parseFloat(recordingPriceTag.attr('raw_price'));
    if(!isNaN(rawPrice)){
        var price = getRounding()(rawPrice);
        price = parseInt(price);
        recordingPriceTag.val(price).change();
    }
}

$(function() {
    $('.section.sales_record').find('.sale_price, .weight').change(function(){
        var itemTag = $(this).closest('.sales_record_item');
        var salePrice = parseInt(itemTag.find('.sale_price').val());

        if(itemTag.hasClass('price_fluctuating')) {
            var weight = parseFloat(itemTag.find('.weight').val());
            if(!isNaN(weight)) {
                var recordingPrice = salePrice * weight;
                setRecordingPrice(itemTag.find('.recording_price'), recordingPrice);
            }
        }else{
            var quantity = parseFloat(itemTag.find('.quantity').val());
            if(!isNaN(quantity)) {
                var recordingPrice = salePrice * quantity;
                setRecordingPrice(itemTag.find('.recording_price'), recordingPrice);
            }
        }
    });
});


$(function() {
    $('.section.sales_record .recording_price').change(function(){
        var rootTag = $(this).closest('.sales_record');

        var totalPrice = 0;
        $.each($('.section.sales_record .recording_price'), function(){
            var price = parseInt($(this).val());
            if(!isNaN(price)){
                totalPrice += price;
            }
        });
        rootTag.find('.total_recording_price').val(totalPrice).change();
    });
});

// 初回の自動計算を起動
$(function() {
    $('.section.sales_record').find('.sale_price').change();
});

// 建値一括変更
$(function() {
    $('.section.sales_record .daily_market_price').change(function(){
        $('.sales_record_item.price-category-day_before .bid_price').val($(this).val()).change();
    });
    $('.section.sales_record .weekly_market_price').change(function(){
        $('.sales_record_item.price-category-week_before .bid_price').val($(this).val()).change();
    });

    // 新規作成の場合は、初めに一括変更を適用して再計算する
    $('.page-sales_records.action-new').find('.daily_market_price, .weekly_market_price').change();
});

// 端数処理
$(function(){
    $('.section.sales_record [name="sales_record[unit_rounding_type_code]"]').change(function(){
        $.each($('.section.sales_record .sale_price'), function(){
            updateSaleUnitPrice($(this));
        });
    });
});
$(function(){
    $('.section.sales_record [name="sales_record[rounding_type_code]"]').change(function(){
        $.each($('.section.sales_record .recording_price'), function(){
            updateRecordingPrice($(this));
        });
    });
});