import * as _ from "./libs/underscore-min"

import * as focusNext from "./focus-next-with-enter-key"
import * as combobox from "./combobox"
import * as reservationCalendar from "./reservation-calendar"

$(function(){
    var setupProductSelector = function(elem){
        $(elem).change(function(){
            $(this).closest('tr').find('input.product_id').val($(this).val())
        });
        $(elem).change();
    };
    setupProductSelector($('td.product-name select'));

    var rootTag = $('body.page-weekly_reservations, body.page-reservations');
    var isWeekly = rootTag.hasClass('page-weekly_reservations');

    var refreshRows = function(){
        // 行番号の振り直し
        _.each($('td.row-number'), function(elem, idx){
            $(elem).find('.row-number-label').text(idx + 1);
        });
        _.each($('td.reservation-product'), function(elem, idx){
            $(elem).find('.order').val(idx);
        });
        _.each($('td.product-values'), function(elem, idx){
            $(elem).find('.reservation_product_order').val(idx);
        });
        if(isWeekly){
            _.each($('.reservation-calendar-table tbody tr'), function(elem, idx){
                $(elem).find('.reservation_product_order').val(idx);
            });
        }

        // 追加ボタンの活性/非活性 切り替え
        if($('td.row-number').length >= 30){
            $(".add-product-button").addClass('disabled')
        } else {
            $(".add-product-button").removeClass('disabled')
        }
    };

    var replaceReservationItemNameAndId = function(elem, attrName, newTrIndex, isWeekly){
        if(isWeekly){
            $(elem).attr('name', $(elem).attr('name').replace(
                new RegExp("buyer\\[reservations_attributes]\\[([0-9]+)]\\[reservation_items_attributes]\\[[0-9]+]\\[" + attrName + "]"),
                'buyer[reservations_attributes][$1][reservation_items_attributes]['+ newTrIndex +'][' + attrName + ']'
            ));
            $(elem).attr('id', $(elem).attr('id').replace(
                new RegExp("buyer_reservations_attributes_([0-9]+)_reservation_items_attributes_[0-9]+_" + attrName),
                'buyer_reservations_attributes_$1_reservation_items_attributes_' + newTrIndex + '_' + attrName
            ));
        } else {
            $(elem).attr('name', $(elem).attr('name').replace(
                new RegExp("reservation\\[reservation_items_attributes]\\[[0-9]+]\\[" + attrName + "]"),
                'reservation[reservation_items_attributes][' + newTrIndex + '][' + attrName + ']'
            ));
            $(elem).attr('id', $(elem).attr('id').replace(
                new RegExp("reservation_reservation_items_attributes_[0-9]+_" + attrName),
                'reservation_reservation_items_attributes_' + newTrIndex + '_' + attrName
            ));
        }
    }

    rootTag.find(".add-product-button").click(function(){
        if($(this).hasClass('disabled')){
            return;
        }

        var tbodyTag = rootTag.find('.reservation-calendar-table tbody');
        var newTrTag = tbodyTag.find('tr:last-child').clone();
        var newTrIndex = tbodyTag.find('tr').length;

        var baseTabIndex = _.max(_.map(newTrTag.find('.quantity'), function(elem){
            return parseInt($(elem).attr('tabindex'));
        })) + 1;

        var reservationItemAttrNames = ["id", "reservation_product_id", "reservation_product_order", "product_id", "product_note", "product_note", "buyer_product_id", "quantity", "unit", "buyer_id"]
        reservationItemAttrNames.forEach(function (attrName) {
            _.each(newTrTag.find('.' + attrName), function(elem, idx) {
                replaceReservationItemNameAndId($(elem), attrName, newTrIndex, isWeekly)
                $(elem).val(null);
            });
        });
        _.each(newTrTag.find('.quantity'), function(elem, idx){
            $(elem).attr('tabindex', (baseTabIndex + 1) + idx * 2); // 「* 2」しているのは quantityとunitで2ずつ進んでいくため
            focusNext.setupFocusNext($(elem));
        });
        _.each(newTrTag.find('.unit'), function(elem, idx){
            $(elem).attr('tabindex', (baseTabIndex + 1) + idx * 2 + 1); // 「* 2」しているのは quantityとunitで2ずつ進んでいくため
            focusNext.setupFocusNext($(elem));
        });
        _.each(newTrTag.find('td.reservation-product input, td.product-note input'), function(elem){
            var attrNames = ["id", "start_date", "end_date", "order", "product_note", "buyer_id", "buyer_product_id", "product_id", "product_note", "created_at", "updated_at"];
            attrNames.forEach(function (attrName) {
                $(elem).attr('name', $(elem).attr('name').replace(
                    new RegExp("reservation_products_attributes\\[[0-9]+\\]\\[" + attrName + "\\]"),
                    'reservation_products_attributes[' + newTrIndex + '][' + attrName + ']'
                ));
                $(elem).attr('id', $(elem).attr('id').replace(
                    new RegExp("reservation_products_attributes_[0-9]+_" + attrName),
                    'reservation_products_attributes_' + newTrIndex + '_' + attrName
                ));
            });
            $(elem).val(null);
        });
        //
        var productSelectorTag = $(".products-selection-template select").clone();
        productSelectorTag.attr(
            "id",
            "reservation_products_attributes_" + newTrIndex + "_product_id"
        ).attr(
            "name",
            "reservation_products_attributes[" + newTrIndex + "][product_id]"
        );
        productSelectorTag.attr("tabindex", baseTabIndex);
        var rowNumberTag = $(newTrTag.find('td.row-number').get(0));
        rowNumberTag.text('').append($("<span>").addClass('row-number-label').text(newTrIndex+1));
        var deleteButtonTag = $('<span>').css({"padding-left": ".2rem"});
        deleteButtonTag.append($('<i>').addClass('fas fa-times-circle').css({color: 'red'}));
        deleteButtonTag.click(function(){
            newTrTag.remove();
            refreshRows();
        });
        rowNumberTag.append(deleteButtonTag);
        reservationCalendar.applyInputToggleStyle(newTrTag.find('input.quantity'));
        $(newTrTag.find('td.product-name').get(0)).html('').append(productSelectorTag);
        //
        tbodyTag.append(newTrTag);
        combobox.applyCombobox(productSelectorTag);
        setupProductSelector(productSelectorTag);
        refreshRows();
    });
});