$(function(){
    var toggleButton = $(".toggle-abnormal-day");
    toggleButton.click(function(){
       if($(this).attr("opened") === "false"){
           $('.abnormal-day').show();
           $(this).text($(this).attr("text-close"));
           $(this).attr("opened", "true");
           $('input#abnormal_day_opened').val(true)
       }else{
           $('.abnormal-day').hide();
           $(this).text($(this).attr("text-open"));
           $(this).attr("opened", "false");
           $('input#abnormal_day_opened').val(false)
       }
    });
});