var applyInputToggleStyle = function(inputs){
    inputs.on('keyup change', function(){
        var td = $(this).closest('td');
        if(isNaN(parseInt($(this).val()))){
            td.removeClass("reserved");
        }else{
            td.addClass("reserved");
        }
    });
    inputs.trigger('change');
};

$(function(){
    var inputs = $('.reservation-calendar-table input.quantity');
    applyInputToggleStyle(inputs)
});

export { applyInputToggleStyle }
