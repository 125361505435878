import 'select2';                       // globally assign select2 fn to $ element
import 'select2/dist/css/select2.css';  // optional if you have css loader
import "select2-bootstrap-theme/dist/select2-bootstrap"; // select2 用の bootstrap テーマの読み込み

import * as focusNext from "./focus-next-with-enter-key"


var applyCombobox = function(selects){
    selects.select2({
        theme: "bootstrap",
        language: {
            noResults: function(params) {
                return "該当するものがありません";
            }
        }
    });
    selects.on("select2:close", function (e) { focusNext.focusNextOf($(this).next('.select2').find('[tabindex]')[0]); });

    selects.change(function(){
        focusNext.focusNextOf($(this).next('.select2').find('[tabindex]')[0]);
    });
};

$(function(){
    applyCombobox($("select.combobox:not(.select2-hidden-accessible)"));
});

$(function(){    
    $("#buyer_products_tabel").on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
        applyCombobox($("select.combobox:not(.select2-hidden-accessible)"));
    });
});

export { applyCombobox }

