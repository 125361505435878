$(function() {
  $(".file_upload").dropzone({
    parallelUploads: 1,
    maxFilesize: 1,
    maxfilesexceeded:1,
    acceptedFiles: ".csv",
    addRemoveLinks: true,

    //日本語化
    dictDefaultMessage: "ここに商品マスタのCSVファイルをドロップしてください。",
    dictFallbackMessage: "お使いのブラウザは、ドラッグアンドドロップによるファイルのアップロードをサポートしていません。",
    dictFallbackText: "以下の代替フォームを使用して、昔のようにファイルをアップロードしてください。",
    dictFileTooBig: "ファイルが大きすぎます ({{filesize}}MiB)。 最大ファイルサイズ：{{maxFilesize}}MiB。",
    dictInvalidFileType: "このタイプのファイルはアップロードできません。",
    dictResponseError: "サーバーは{{statusCode}}コードで応答しました。",
    dictCancelUpload: "アップロードをキャンセル",
    dictCancelUploadConfirmation: "このアップロードをキャンセルしてもよろしいですか？",
    dictRemoveFile: "ファイルを削除",
    dictMaxFilesExceeded: "これ以上ファイルをアップロードできません。",

    init: function() {
      this.on('addedfile', function(file) {
        //ファイルが2つになると1つ目を削除する
        if (this.files.length > 1) {
          this.removeFile(this.files[0]);
          console.log("ファイルが削除された(2つ以上のアップロード)");
          console.log(file);
          console.log(file.name);
        }

        //ファイルが入力されたときに、１つ前のファイルの情報を消す
        $('input[name="file_path"]').val(null);
        var botton = document.getElementById("upload_file_botton");
        botton.disabled = true;
      });

      this.on('removedfile', function(file) {
        //ファイルが削除されたときに、ファイルの情報を消す
        $('input[name="file_path"]').val(null);
        var botton = document.getElementById("upload_file_botton");
        botton.disabled = true;
      });
    },

    success: function(file, response, event){
      console.log(response.file_path)
      $('input[name="file_path"]').val(response.file_path);
      var botton = document.getElementById("upload_file_botton");
      botton.disabled = false;
    },

  });
  
});