
$(function(){
    var onChange = function(){

        console.log(inputs)

        var selected_category = document.getElementsByName(this.name);
        var selected_value = selected_category.item(0).value;
        //id検索から、変更すべきprice見つける
        var this_id = this.id;
        var this_price_id = this_id.replace('price_category','price');
        var selected_price_field = document.getElementById(this_price_id);
        //固定価格が選ばれた場合
        if (selected_value === "fixed_price" ){
            selected_price_field.disabled = false;
        }else{
            //固定価格以外が選ばれた場合
            selected_price_field.value =null;
            selected_price_field.disabled = true;
        }
    }

    var inputs = $('.page-buyer_products .price-category-selector');
    inputs.on('change', onChange);
    inputs.change();

    //追加ボタンが押された時に動く（カラムが追加された後）
    $('#sotable-insertable-table').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
        $(insertedItem).find('.price-category-selector').on('change', onChange).change();
    });

});