$(function(){
    var inputs = $('.calendar-input-table input');
    inputs.on('keyup change', function(){
        var tr = $(this).closest('tr');
        var prices = tr.find('.each_price .form-control');

        var sum = 0;
        var num = 0;
        var result = NaN;
    
        prices.each(function(index, element){
            if(isNaN(parseInt($(element).val()))){
                
            }else{
                sum += parseInt($(element).val());
                num += 1;
            }
        });
        
        // その1 ifの中身が否定
        if (num != 0) {
            result = sum / num;
        }

        // その2 こっちの書き方のがよいのか
        // var result = (num==0) ? NaN : sum/num;

        var ave = tr.find('.weekly_avarage input');

        ave.val(result.toFixed(0));
        ave.closest('td').addClass("ave-result");
    });
    inputs.trigger('change');
});