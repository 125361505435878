$(function() {

    var refreshOrder = function(){
        $.each($('#buyer_products_tabel tbody .order_number'), function(index){
            $(this).val(index);
        });
    };

    $('#sotable-insertable-table').sortable({
        update: function(event,ui){
            refreshOrder();
        }
    });

    if($('#sotable-insertable-table').length > 0){
        refreshOrder();
    }

});
