import "bootstrap"
import * as moment from 'moment/moment'
import 'bootstrap4-datetimepicker'
import 'moment/locale/ja'

$(function(){

    // 月曜始まりにする
    moment.locale('ja', {
        week: {
            dow: 1
        }
    });

    // 公式 https://bootstrap-datepicker.readthedocs.io/en/latest/events.html
    // Option一覧　https://qiita.com/haserror/items/de01d46fad1a54c61182

    var picker = $('.page-sales_aggregates.action-index .datepicker-aggregate').datetimepicker({
        dayViewHeaderFormat: 'YYYY年 MM月',
        tooltips: {
            close: '閉じる',
            selectMonth: '月を選択',
            prevMonth: '前月',
            nextMonth: '次月',
            selectYear: '年を選択',
            prevYear: '前年',
            nextYear: '次年',
            selectTime: '時間を選択',
            selectDate: '日付を選択',
            prevDecade: '前期間',
            nextDecade: '次期間',
            selectDecade: '期間を選択',
            prevCentury: '前世紀',
            nextCentury: '次世紀'
        },
        format: 'YYYY/MM/DD',
        locale: 'ja',
        showClose: true,
        daysOfWeekDisabled: [0, 2, 3, 4, 5, 6]
    });

    picker.on('dp.change', function(e){
        var startDate = $(".start-date").val();
        if(startDate) {
            var endDate = moment(startDate.replace(/\//g, '-')).add(5, 'days').format("YYYY-MM-DD");
            $(".end-date").val(endDate);
        } else {
            $(".end-date").val(null);
        }
    });

});